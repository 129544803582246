import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpEvent } from '@angular/common/http'
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  BaseUrl: any = environment.BaseUrl;
  constructor(private http: HttpClient) { }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }

  // CREATE NEW EMPLOYEE
  createnewemployee(body){
    return this.http.post(this.BaseUrl + '/employee/create',body, httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  employeefulldetailsparti(body) {
    return this.http.post(this.BaseUrl + '/particular/employee/data',body, httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  getdistinctemployeeroles(){
    return this.http.get(this.BaseUrl + '/get/distinct/roles', httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getallemployeesrolewise(){
    return this.http.post(this.BaseUrl + '/get/all/employees/emprolewise', httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  getallemplist(){
    return this.http.get(this.BaseUrl + '/all/emp/list', httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  changeworkingstatusemp(body){
    return this.http.put(this.BaseUrl + '/update/working/status/empwise',body, httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  changepassword(body){
    return this.http.put(this.BaseUrl + '/change/password/emp',body, httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  deleterolepartemp(RoleID){
    return this.http.delete(this.BaseUrl + '/delete/role/emp/parti/'+RoleID).pipe(
      catchError(this.handleError)
    );
  }
  addnewrolepartemp(body){
    return this.http.post(this.BaseUrl + '/add/new/role/prodemp',body, httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  updatepartiempdata(body){
    return this.http.put(this.BaseUrl + '/update/parti/emp/data',body, httpOptions).pipe(
      catchError(this.handleError)
    );
  }

}
